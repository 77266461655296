import LogoMark from "../assets/Logomark.svg";
import MartingaleHorizontal from "../assets/MartingaleHorizontal.svg";
import Mail from "../assets/Mail.svg";
import GreenSparkle from "../assets/GreenSparkle.svg";
import RedSparkle from "../assets/RedSparkle.svg";
import YellowSparkle from "../assets/YellowSparkle.svg";
import DarkSparkle from "../assets/DarkSparkle.svg";

export default function Top() {
  const mailHandler = () => {
    window.open("mailto:contact@martingale.consulting");
  };

  return (
    <div className="bg-green-dark h-screen  overflow-hidden flex flex-col items-center justify-center">
      <img
        className="-z-1 scale-200 object-cover sm:transform-none"
        src={LogoMark}ƒ
        alt="Logo Mark"
      />
      
      <div className="z-10 absolute text-white flex flex-col items-center text-center">
        <img src={MartingaleHorizontal} alt="Martingale Horizontal" />
        <div className="px-12 pb-12 max-w-2xl">
          Martingale is a premier management consulting firm that specialises in
          providing strategic, operational, and financial advisory to
          businesses.
        </div>
        <img
          src={Mail}
          alt="Mail"
          className="cursor-pointer w-12 lg:w-16"
          onClick={mailHandler}
        />
      </div>
      <img
        src={GreenSparkle}
        alt="Green Sparkle"
        className="absolute right-2/3 bottom-[32%] animate-sparkleAlt -z-1 w-6 sm:w-8"
      />
      <img
        src={DarkSparkle}
        alt="Dark Sparkle"
        className="absolute top-[25%] animate-sparkle right-[18%] w-4 sm:w-3"
      />
      <img
        src={YellowSparkle}
        alt="Yellow Sparkle"
        className="absolute top-[15%] right-[20%] animate-sparkleAlt -z-1 w-6 sm:w-10"
      />
      <img
        src={RedSparkle}
        alt="Red Sparkle"
        className="absolute top-1/4 left-[15%] animate-sparkleAlt -z-1 w-8 sm:w-8 sm:top-1/3"
      />
      <img
        src={DarkSparkle}
        alt="Dark Sparkle"
        className="absolute top-3/4 right-1/4 animate-sparkle -z-1 w-5 sm:w-8"
      />
    </div>
  );
}
