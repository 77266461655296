import Top from "../components/Top";
import Bottom from "../components/Bottom";
import Navbar from "../components/Navbar";

export default function LandingPage() {
  return (
    <div className="font-sans">
      <Navbar />
      <Top />
      <Bottom />
    </div>
  );
}
