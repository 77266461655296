import React, { useState } from "react";

import Smiley from "../assets/Smiley.svg";
import Crown from "../assets/Crown.svg";
import Diamond from "../assets/Diamond.svg";

export default function Bottom() {
  const [hoverSmiley, setHoverSmiley] = useState(false);
  const [hoverCrown, setHoverCrown] = useState(false);
  const [hoverDiamond, setHoverDiamond] = useState(false);

  const mailHandler = () => {
    window.open("mailto:contact@martingale.consulting");
  };

  return (
    <>
      <div className="hidden break:block">
        <div className="grid grid-cols-6 sm:grid-cols-10 relative">
          <div className="bg-green aspect-square" />
          <div className="bg-yellow aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-yellow aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-green aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-yellow aspect-square" />

          <div className="bg-turquoise aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-yellow aspect-square" />
          <div
            className="aspect-square"
            onMouseEnter={() => setHoverSmiley(true)}
            onMouseLeave={() => setHoverSmiley(false)}
          >
            <img src={Smiley} className="w-full" alt="Smiley" />
          </div>

          {hoverSmiley && (
            <div className="bg-green-dark row-span-2 col-span-2 col-start-7 row-start-2 text-xs p-3 lg:p-6 xl:text-base 3xl:text-2xl 2xl:p-8 text-white animate-fade">
              <div className="text-yellow text-sm font-bold pb-2 lg:pb-4 xl:text-xl 2xl:text-3xl">
                Operational Efficiency
              </div>
              Services to optimise operational processes, reducing costs and
              enhancing productivity.
            </div>
          )}
          {!hoverSmiley && (
            <>
              <div className="bg-green aspect-square animate-fade" />
              <div className="bg-turquoise aspect-square animate-fade" />
            </>
          )}
          <div className="bg-red aspect-square" />
          <div className="bg-turquoise aspect-square" />

          <div className="bg-yellow aspect-square" />
          <div
            className="aspect-square"
            onMouseEnter={() => setHoverCrown(true)}
            onMouseLeave={() => setHoverCrown(false)}
          >
            <img src={Crown} className="w-full" alt="Crown" />
          </div>
          {hoverCrown && (
            <div className="bg-green-dark row-span-2 col-span-2 col-start-3 row-start-3 text-xs p-3 lg:p-6 xl:text-base 3xl:text-2xl 2xl:p-8 text-white animate-fade">
              <div className="text-yellow text-sm font-bold pb-2 lg:pb-4 xl:text-xl 2xl:text-3xl">
                Strategic Planning
              </div>
              Assists organisations in defining their strategic direction,
              setting goals and developing actionable plans to realise these
              visions.
            </div>
          )}
          {!hoverCrown && (
            <>
              <div className="bg-red aspect-square animate-fade" />
              <div className="bg-turquoise aspect-square animate-fade" />
            </>
          )}
          <div className="bg-green aspect-square" />
          <div className="bg-red aspect-square" />
          {!hoverSmiley && (
            <>
              <div className="bg-yellow aspect-square animate-fade" />
              <div className="bg-red aspect-square animate-fade" />
            </>
          )}
          <div className="bg-yellow aspect-square" />
          <div className="bg-green aspect-square" />

          <div className="bg-red aspect-square" />
          <div className="bg-yellow aspect-square" />
          {!hoverCrown && (
            <>
              <div className="bg-turquoise aspect-square animate-fade" />
              <div className="bg-green aspect-square animate-fade" />
            </>
          )}
          <div className="bg-yellow aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div
            className="aspect-square"
            onMouseEnter={() => setHoverDiamond(true)}
            onMouseLeave={() => setHoverDiamond(false)}
          >
            <img src={Diamond} className="w-full" alt="Diamond" />
          </div>
          {hoverDiamond && (
            <div className="bg-green-dark row-span-2 col-span-2 col-start-8 row-start-4 text-xs p-3 lg:p-6 xl:text-base 3xl:text-2xl 2xl:p-8 text-white animate-fade">
              <div className="text-yellow text-sm font-bold pb-2 lg:pb-4 xl:text-xl 2xl:text-3xl">
                Financial Advisory
              </div>
              Martingale provides financial analysis and advice to improve
              profitability, manage risks, and secure financing.
            </div>
          )}
          {!hoverDiamond && (
            <>
              <div className="bg-turquoise aspect-square animate-fade" />
              <div className="bg-red aspect-square animate-fade" />
            </>
          )}
          <div className="bg-yellow aspect-square" />
          <div className="bg-yellow aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-green aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-yellow aspect-square" />
          <div className="bg-turquoise aspect-square" />
          {!hoverDiamond && (
            <>
              <div className="bg-green aspect-square animate-fade" />
              <div className="bg-yellow aspect-square animate-fade" />
            </>
          )}
          <div className="bg-turquoise aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-green aspect-square" />
          <div className="bg-yellow aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-green aspect-square" />
          <div className="bg-yellow aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-green aspect-square" />
        </div>
        <div className="flex flex-row justify-between items-center p-8 text-green bg-turquoise-dark font-medium">
          <div onClick={mailHandler} className="cursor-pointer font-semibold">
            contact@martingale.consulting
          </div>
          <div className="py-3">© Martingale 2024</div>
        </div>
      </div>

      <div className="break:hidden">
        <div className="grid grid-cols-6 relative">
          <div className="bg-yellow aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-green aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-yellow aspect-square" />

          <div className="bg-turquoise aspect-square" />
          <img src={Crown} alt="Crown" className="aspect-square" />
          <div className="row-span-5 col-span-4 col-start-2 row-start-3 bg-green-darks p-6 text-base text-white sm:text-xl">
            <div className="text-yellow text-lg font-bold pb-4 sm:text-2xl">
              Strategic Planning
            </div>
            Assists organisations in defining their strategic direction, setting
            goals and developing actionable plans to realise these visions.
          </div>

          <div className="bg-red aspect-square" />
          <div className="bg-yellow aspect-square" />

          <div className="bg-turquoise aspect-square" />
          <div className="bg-red aspect-square" />

          <div className="bg-yellow aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-yellow aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-turquoise aspect-square" />

          <div className="bg-yellow aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-yellow aspect-square" />
          <div className="bg-red aspect-square" />

          <img src={Smiley} alt="Smiley" className="aspect-square" />
          <div className="row-span-4 col-span-4 col-start-2 row-start-9 bg-green-dark p-6 text-white sm:text-xl">
            <div className="text-yellow text-lg font-bold pb-4 sm:text-2xl">
              Operational Efficiency
            </div>
            Services to optimise operational processes, reducing costs and
            enhancing productivity.
          </div>
          <div className="bg-red aspect-square" />
          <div className="bg-yellow aspect-square" />

          <div className="bg-turquoise aspect-square" />
          <div className="bg-red aspect-square" />

          <div className="bg-yellow aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-yellow aspect-square" />

          <div className="bg-yellow aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-green aspect-square" />

          <img src={Diamond} alt="Diamond" className="aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-yellow aspect-square" />

          <div className="bg-red aspect-square" />
          <div className="bg-green aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="row-span-4 col-span-4 col-start-2 row-start-14 bg-green-dark p-6 text-white sm:text-xl">
            <div className="text-yellow text-lg sm:text-2xl font-bold pb-4">
              Financial Advisory
            </div>
            Martingale provides financial analysis and advice to improve
            profitability, manage risks, and secure financing.
          </div>
          <div className="bg-red aspect-square" />
          <div className="bg-yellow aspect-square" />

          <div className="bg-yellow aspect-square" />
          <div className="bg-red aspect-square" />

          <div className="bg-turquoise aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-red aspect-square" />
          <div className="bg-yellow aspect-square" />
          <div className="bg-turquoise aspect-square" />
          <div className="bg-yellow aspect-square" />

          <div className="bg-red aspect-square" />
          <div className="bg-yellow aspect-square" />
          <div className="bg-turquoise aspect-square" />
        </div>
        <div className="flex flex-col justify-between items-center p-8 text-green-dark bg-turquoise-dark font-medium">
          <div onClick={mailHandler} className="">contact@martingale.consulting</div>
          <div className="py-3">© Martingale 2024</div>
        </div>
      </div>
    </>
  );
}
